let domain_text = "Reviewbucket.co.uk"
export const msg = {
    "product_search": "Search product...",

    "home_page_title": domain_text + " - All Product Reviews On The Internet",
    "home_page_desc": "All of the product reviews on the internet are in " + domain_text,
    "home_page_canonical": "https://reviewbucket.co.uk",
    "home_page_landing_title": "All Reviews in One Place",
    "home_page_landing_desc": "<b>" + domain_text + "</b> Analyzes millions of product reviews from hundreds of websites and shows you the best ones.",


    "user_review_appendix": "reviews",
    "user_review_appendix_1": "review",
    "user_review_appendix_2_3_4": "reviews",
    "user_review_expansian_panel_appendix": "- Reviews",
    "read_reviews_button_text": "Read reviews",
    "infinite_loop_loading": "Loading...",
    "infinite_loop_end": "End.",
    "price_with_currency": "x_price_x",

    "expansion_details_text": domain_text + " scanned the internet for <b>x_title_x reviews</b>.<br />You can find all <b>x_title_x reviews</b> and ratings on this page.",

    "product_page_title_appendix": "Reviews - " + domain_text,
    "product_page_desc": "x_title_x reviews",

    "why_good": "GOOD",
    "why_bad": "BAD",
    "read_more": "Read more..",
    "more": "More",
    "review": "Reviews",
    "review_1": "Review",
    "review_2_3_4": "Reviews",
    "similar_items": "Similar Items",
    "list_bests_in_the_cat": "List the best ones in this category!",
    "read_before_buying": "Read the info about this product!",
    "no_review": "Bu ürün için yorum bulunmamaktadır. Aşağıdan bu kategorideki diğer ürünlere göz atabilirsiniz.",

    "terms_and_cond": "Terms and Conditions",
    "privacy_policy": "Privacy Policy",

    "categories": "Categories",
    "back": "Back",
    "home": "Home",
    "most_review": "Most Reviewed",
    "most_rated": "Highest Rated",
    "most_expensive": "Highest Price",
    "most_cheap": "Lowest Price",
    "read_about_cat": "Learn everything about these products!",

    "product_page": {
        "info": "Info",
        "go_to_comments": "Read the reviews.",
        "analyse": "Analysis",
        "general_stats": "For <b>x_title_x</b>, <b>x_reviewcount_x</b> customer reviews collected from <b>x_sitecount_x</b> e-commerce sites, and the average score is <b>x_avgstar_x</b>.",
        "stats_by_sellers": "Detailed seller stats;",
        "seller_stats": "<b>x_sitename_x</b> has <b>x_reviewcount_x</b> customer reviews and the average score is <b>x_avgstar_x</b>.",
        "go_to_seller": "Go to this seller.",
        "detail": "Detail",
        "go_to_category": "Click to list all products in this category.",
        "go_to_article": "Click to learn everything about this product.",
        "recommended_seller": "Recommended Seller",
        "go_to_recommended_seller": "Go to shop",
        "bests_in_this_cat": "Bests at this category",
        "buyers_guide": "Buyer's Guide",
    },
    "article": {
        "content_text": "Table Of Contents",
        "related_articles": "Similar Articles",
        "show_items_in_cat": "List All Products",
        "dynamic_section_title": "ReviewBucket's x_keyword_x Analysis",
        "dynamic_section_summary": `ReviewBucket scanned all the E-Commerce sites for x_keyword_x and analyzed the users' reviews about it.
                                    According to our AI algorithm's analysis results, we extracted 2 products for your interest. 
                                    The first one is the best product in terms of price-performance ratio and 
                                    the second one is the best product in terms of quality. You can see these products below.`,

        "dynamic_section_fp_desc": `<b>For those who looking for the best x_keyword_x in terms of price-performance ratio</b>, we highly recommend
                                    <a href=\"/x_product_link_x\"> x_product_name_x</a>.
                                    According to our results, <b>x_review_count_x</b> users reviewed <b>x_product_name_x</b> 
                                    and the average score given by the users for this product is <b>x_avg_star_x</b> out of 5.
                                    Considering its cheap price and the analysis results of its users' reviews, we can easily say that 
                                    this product has the best price-performance ratio.`,

        "dynamic_section_bp_desc": `ReviewBucket AI technology chosen the <a href=\"/x_product_link_x\"> x_product_name_x</a>
                                    for those who want to buy the best x_keyword_x in terms of quality.
                                    According to our results, <b>x_review_count_x</b> users reviewed <b>x_product_name_x</b> 
                                    and the average score given by the users for this product is <b>x_avg_star_x</b> out of 5.
                                    Although it is a bit expensive compared to its alternatives,
                                    the users say that it is more durable and qualified than the others.`,

        "items_in_cat_text": `<a href=\"/x_cat_link_x\">Here are the other products in this category along with their users' reviews;</a>`,

    },

    "search_page": {
        "info": "<b>x_res_count_x</b> results for \"<b>x_search_term_x</b>\""
    }
}